<style lang="scss" scoped src="./layout.scss"></style>

<template>
    <div :class="classObj" class="app-wrapper">
        <div v-if="classObj.mobile && sidebar.opened" class="drawer-bg" @click="handleClickOutside"/>
        <sidebar class="sidebar-container" :collapse="classObj.hideSidebar"/>
        <div class="main-container">
            <navbar/>
            <app-main/>
        </div>

        <login-dialog/>
    </div>
</template>

<script lang="ts">
    import { Navbar, AppMain, Sidebar } from './components'
    import ResizeMixin from './mixin/ResizeHandler'
    import { Component } from 'vue-property-decorator'
    import { mixins } from 'vue-class-component'
    import { DeviceType, AppModule } from '@/store/modules/app'
    import { LoginDialog } from "@/components/login-dialog";

    @Component({
        components: {
            Navbar,
            Sidebar,
            AppMain,
            LoginDialog
        }
    })
    export default class Layout extends mixins(ResizeMixin) {
        get classObj() {
            return {
                hideSidebar: !this.sidebar.opened,
                openSidebar: this.sidebar.opened,
                withoutAnimation: this.sidebar.withoutAnimation,
                mobile: this.device === DeviceType.Mobile
            }
        }

        private handleClickOutside() { AppModule.CloseSideBar(false) }
    }
</script>
