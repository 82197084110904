import {IRequester} from "@/model/ServerApi";
import {DetectorListNotification} from "@/model/server/notifications/DetectorListNotification";

export class NotificationsServerApi {

    constructor(private requester: IRequester) {}

    getDetectorlistNotifications(): Promise<DetectorListNotification[]> {
        return this.requester.get('/notifications/getdetectorlistnotifications');
    }

    createDetectorlistNotification(arg: DetectorListNotification): Promise<boolean> {
        return this.requester.post('/notifications/createdetectorlistnotification', arg);
    }

    modifyDetectorlistNotification(arg: DetectorListNotification): Promise<boolean> {
        return this.requester.post('/notifications/modifydetectorlistnotification', arg);
    }

    removeDetectorlistNotification(id: number): Promise<boolean> {
        return this.requester.post('/notifications/removedetectorlistnotification?id=' + id);
    }
}
