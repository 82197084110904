import {IRequester} from "@/model/ServerApi";
import {Detectorlist} from "@/model/server/Detectorlist";
import {DetectorlistFilter} from "@/model/server/DetectorlistFilter";
import {DetectorlistFilterResult} from "@/model/server/DetectorlistFilterResult";
import {DetectorlistProcessUploadedFileArg} from "@/model/bindings/DetectorlistProcessUploadedFileArg";
import {BlacklistTransactionArg} from "@/model/bindings/BlacklistTransactionArg";
import {DetectorlistProcessTransactionResponse} from "@/model/bindings/DetectorlistProcessTransactionResponse";
import {ProcessUploadedFileStandardResponse} from "@/model/bindings/ProcessUploadedFileStandardResponse";

export class DetectorlistServerApi {
    constructor(private requester: IRequester) {
    }

    get(id: string): Promise<Detectorlist> {
        return this.requester.get('/detectorlist/get?id=' + id);
    }

    delete(id: string): Promise<boolean> {
        return this.requester.post('/detectorlist/delete?id=' + id);
    }

    list(params: DetectorlistFilter): Promise<DetectorlistFilterResult> {
        return this.requester.post('/detectorlist/list', params);
    }

    add(detectorlist: Detectorlist): Promise<Detectorlist> {
        return this.requester.post('/detectorlist/add', detectorlist);
    }

    mutate(detectorlist: Detectorlist): Promise<boolean> {
        return this.requester.post('/detectorlist/mutate', detectorlist);
    }

    blacklistFromTransaction(arg: BlacklistTransactionArg): Promise<DetectorlistProcessTransactionResponse> {
        return this.requester.post('/detectorlist/blacklistfromtransaction', arg);
    }

}
