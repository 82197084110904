<template>
    <el-dialog :title="$t('Merchant')" :visible.sync="innerVisible" :top="getTop()" :close-on-click-modal="false">

        <el-form v-if="innerMerchant"
                 v-loading="loading"
                 ref="form"
                 :model="innerMerchant"
                 :show-message="false"
                 label-width="250px"
                 @submit.native.prevent="save"
        >
            <el-form-item :label="$t('Gateway')" prop="gateway" :rules="[{ required:true }]">
                <gateway-selector v-model="innerMerchant.gateway" :disabled="!!innerMerchant.id"/>
            </el-form-item>

            <el-form-item :label="$t('Key')" prop="key_name">
                <el-input v-model="innerMerchant.key_name" :disabled="!!innerMerchant.id"/>
            </el-form-item>

            <el-form-item :label="$t('Name')" prop="display_name" :rules="[{ required:true }]">
                <el-input v-model="innerMerchant.display_name" :disabled="!!innerMerchant.id"/>
            </el-form-item>

            <el-form-item :label="$t('Company')" prop="company">
                <el-input v-model="innerMerchant.company"/>
            </el-form-item>

            <el-form-item :label="$t('Manager')" prop="manager">
                <el-input v-model="innerMerchant.manager"/>
            </el-form-item>

            <el-form-item :label="$t('Country Code')" prop="country_code">
                <el-select v-model="innerMerchant.country_code">
                    <el-option v-for="code in codes" :key="code" :label="$t(code)" :value="code"/>
                </el-select>
            </el-form-item>

            <el-form-item :label="$t('Business')" prop="business" :rules="[{ required:true }]">
                <business-selector v-model="innerMerchant.business"/>
            </el-form-item>

            <el-form-item :label="$t('Role')" prop="role" :rules="[{ required:true }]">
                <el-select v-model="innerMerchant.role">
                    <el-option v-for="role in roles" :key="role.value" :label="$t(role.label)" :value="role.value"/>
                </el-select>
            </el-form-item>

            <el-form-item :label="$t('Status')" prop="status">
                <el-select v-model="innerMerchant.status" :disabled=true>
                    <el-option v-for="status in statuses" :key="status.value" :label="$t(status.label)" :value="status.value"/>
                </el-select>
            </el-form-item>

            <el-form-item :label="$t('First txn at')" >
                <el-input v-model="first_transaction" :disabled="true"/>
            </el-form-item>

            <el-form-item :label="$t('Last txn at')" >
                <el-input v-model="last_transaction" :disabled="true"/>
            </el-form-item>

            <el-form-item :label="$t('Emails')" prop="emails">
                <el-input v-model="innerMerchant.emails" :title="$t('Use \';\' as delimiter.')"/>
            </el-form-item>

            <el-form-item :label="$t('Language')" prop="language">
                <language-selector v-model="innerMerchant.language"/>
            </el-form-item>

            <div class="el-dialog-form-buttons">
                <el-button native-type="submit" type="primary">{{ $t('Save') }}</el-button>
                <el-button @click="close">{{ $t('Cancel') }}</el-button>
                <el-button type="danger" @click="remove" style="float:right">{{ $t('Remove') }}</el-button>
            </div>

        </el-form>

    </el-dialog>
</template>

<script lang="ts">
    import { Component, Vue, Prop, Emit, Watch, PropSync } from 'vue-property-decorator';
    import { Gateway } from "@/model/server/Gateway";
    import { ElForm } from "element-ui/types/form";
    import { Merchant, MerchantTools } from "@/model/server/Merchant";
    import { LanguageSelector } from "@/components/language-selector";
    import { MerchantRoleTools } from "@/model/server/MerchantRole";
    import { MerchantStatusTools } from "@/model/server/MerchantStatus";
    import { GatewaySelector } from "@/components/gateway-selector";
    import { DataTools } from "@/model/DataTools";
    import { BusinessSelector } from "@/components/business-selector";
    import { messages } from "@/views/merchants/merchants-list/messages";

    @Component({
        components: { GatewaySelector, LanguageSelector, BusinessSelector },
        i18n: { messages }
    })
    export default class MerchantDialog extends Vue
    {
        @PropSync('visible') innerVisible: boolean;

        innerMerchant: Merchant = null;
        @Prop() merchant: Merchant;
        @Watch("merchant") onMerchantChanged() { this.innerMerchant = this.merchant ? MerchantTools.clone(this.merchant) : null }
        @Emit("update:merchant") updateMerchant(e) { this.innerMerchant = e }

        @Emit("remove") emitRemove(e) {}

        roles = MerchantRoleTools.options;

        codes = null;

        statuses = MerchantStatusTools.options;

        get first_transaction() { return this.innerMerchant ? DataTools.dateToString(this.innerMerchant.first_transaction, "hours-minutes-seconds") : null }

        get last_transaction() { return  this.innerMerchant ? DataTools.dateToString(this.innerMerchant.last_transaction, "hours-minutes-seconds") : null }

        loading = false;

        gateways: Gateway[] = null;

        getTop() { return Math.max(0, (window.innerHeight - 700) / 2) + "px" }

        created()
        {
            this.$serverApi.gateway.getRelated().then(gateways => {this.gateways = gateways;});
            this.$serverApi.common.getEEACountries().then(countries => {this.codes = Object.values(countries);});
        }

        save()
        {
            (<ElForm>this.$refs.form).validate(async valid => {
                if (!valid) {
                    return;
                }

                this.loading = true;
                try {
                    let merchant = this.innerMerchant.id ? await this.$serverApi.merchant.save(this.innerMerchant)
                                                         : await this.$serverApi.merchant.add(this.innerMerchant);
                    this.updateMerchant(merchant);
                    this.close();
                }
                finally {
                    this.loading = false;
                }
            });
        }

        close()
        {
            this.innerVisible = false;
        }

        async remove()
        {
            if (!confirm(<any>this.$t("Are you sure to remove this merchant?"))) {
                return;
            }

            this.loading = true;
            try {
                await this.$serverApi.merchant.remove(this.innerMerchant.id);
                this.emitRemove(this.innerMerchant);
                this.close();
            }
            finally {
                this.loading = false;
            }
        }
    }
</script>
