import {Vue} from 'vue-property-decorator';
import {MessageType} from "element-ui/types/message";
import {ElNotificationComponent} from "element-ui/types/notification";
import {TranslateResult} from "vue-i18n";

class Message {
    component: ElNotificationComponent;
    timeout: any;

    constructor(public type: MessageType, public title: string, public message: string, public seconds: number) {
        this.type = type;
        this.title = title;
        this.message = message;
        this.seconds = seconds;
    }

    equ(m: Message): boolean {
        return this.type === m.type
            && this.title === m.title
            && this.message === m.message;
    }
}

export class Messenger {
    private static messages = new Array<Message>();

    public static success(title: string | TranslateResult, message: string | TranslateResult = null, seconds = 3): void {
        Messenger.add('success', <string>title, <string>message, seconds);
    }

    public static warning(title: string | TranslateResult, message: string | TranslateResult = null, seconds = 3): void {
        Messenger.add('warning', <string>title, <string>message, seconds);
    }

    public static info(title: string | TranslateResult, message: string | TranslateResult = null, seconds = 3): void {
        Messenger.add('info', <string>title, <string>message, seconds);
    }

    public static error(title: string | TranslateResult, message: string | TranslateResult = null, seconds = 3): void {
        Messenger.add('error', <string>title, <string>message, seconds);
    }

    public static clear(): void {
        for (let m of Messenger.messages) {
            if (m.timeout) {
                clearTimeout(m.timeout);
            }
            if (m.component) {
                m.component.close();
            }
        }
        Messenger.messages = [];
    }

    private static add(type: MessageType, title: string, message: string, seconds: number): void {
        if (message === null) {
            message = title;
            title = null;
        }

        let m = new Message(type, title, message, seconds);
        if (Messenger.messages.some(x => x.equ(m))) {
            return;
        }

        Messenger.messages.push(m);

        m.timeout = setTimeout(() => {
            m.component = (<Vue>(<any>window).app).$notify({
                type: type,
                title: title,
                dangerouslyUseHTMLString: true,
                message: message,
                duration: seconds * 1000,
                onClose: () => {Messenger.messages = Messenger.messages.filter(x => x !== m);}
            });
        }, 1);
    }
}
