export enum MerchantRole {
    MERCHANT = 'MERCHANT',
    TEST = 'TEST',
}

export class MerchantRoleTools {
    public static readonly options = [
        {value: MerchantRole.MERCHANT, label: 'MERCHANT_ROLE_MERCHANT'},
        {value: MerchantRole.TEST, label: 'MERCHANT_ROLE_TEST'},
    ];
}
