import {IRequester} from "@/model/ServerApi";
import {UserLoginResponse} from "@/model/bindings/UserLoginResponse";
import {DataTools} from "@/model/DataTools";

export class LoginServerApi {
    constructor(private requester: IRequester) {
    }

    async login(username: string, password: string): Promise<UserLoginResponse> {
        let r: UserLoginResponse = await this.requester.post('/login', {name: username, password: password});
        r.expireMoment = DataTools.parseServerDate(r.expireMoment);
        if (r.user != null && r.user.password_change_time != null) {
            r.user.password_change_time = DataTools.parseServerDate(r.user.password_change_time);
        }
        if (r.user != null && r.user.last_login_time != null) {
            r.user.last_login_time = DataTools.parseServerDate(r.user.last_login_time);
        }
        return r;
    }
}
