import {CustomListValue} from "@/model/server/CustomListValue";
import {CustomList} from "@/model/server/CustomList";
import {CustomListFilter} from "@/model/server/CustomListFilter";
import {CustomListFilterResult} from "@/model/server/CustomListFilterResult";
import {IRequester} from "@/model/ServerApi";
import {CustomListValuesProcessUploadedFileArg} from "@/model/bindings/CustomListValuesProcessUploadedFileArg";
import {ProcessUploadedFileStandardResponse} from "@/model/bindings/ProcessUploadedFileStandardResponse";

export class CustomListServerApi {
    constructor(private requester: IRequester) {
    }

    getLists(): Promise<CustomList[]> {
        return this.requester.get('/customlist/lists');
    }

    getValues(filter: CustomListFilter): Promise<CustomListFilterResult> {
        return this.requester.post('/customlist/values', filter);
    }

    getValuesByName(gateway: number, listname: string): Promise<CustomListValue[]> {
        return this.requester.get('/customlist/valuesbyname?gateway=' + gateway + '&name=' + listname);
    }

    existsList(gateway: number, listname: string): Promise<boolean> {
        return this.requester.get('/customlist/listexists?gateway=' + gateway + '&name=' + listname);
    }

    addList(list: CustomList): Promise<CustomList> {
        return this.requester.post('/customlist/addlist', list);
    }

    saveList(list: CustomList): Promise<CustomList> {
        return this.requester.post('/customlist/savelist', list);
    }

    removeList(id: number): Promise<void> {
        return this.requester.post('/customlist/removelist/' + id);
    }

    addValue(value: CustomListValue): Promise<CustomListValue> {
        return this.requester.post('/customlist/addvalue', value);
    }

    saveValue(value: CustomListValue): Promise<CustomListValue> {
        return this.requester.post('/customlist/savevalue', value);
    }

    removeValue(id: number): Promise<void> {
        return this.requester.post('/customlist/removevalue/' + id);
    }
}
