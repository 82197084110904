import {IRequester} from "@/model/ServerApi";
import {TransactionListFraudArg} from "@/model/bindings/TransactionListFraudArg";
import {TransactionListFraudResponse} from "@/model/bindings/TransactionListFraudResponse";
import {FraudParameter} from "@/model/bindings/SetFraudArg";
import {PotentialFraudParameter} from "@/model/bindings/PotentialFraudParameter";
import {FraudFieldVariants} from "@/model/server/FraudFieldVariants";
import {FraudGetStatisticArg} from "@/model/bindings/FraudGetStatisticArg";
import {FraudGetStatisticResponse} from "@/model/bindings/FraudGetStatisticResponse";
import {TransactionListPFraudArg} from "@/model/bindings/TransactionListPFraudArg";
import {TransactionListPFraudResponse} from "@/model/bindings/TransactionListPFraudResponse";

export class FraudServerApi {
    constructor(private requester: IRequester) {
    }

    send(fraud: FraudParameter): Promise<boolean> {
        return this.requester.post('/fraud/send', fraud);
    }

    sendPotentialFraudList(pfraud: TransactionListPFraudArg): Promise<TransactionListPFraudResponse> {
        return this.requester.post('/fraud/sendpotentialfraudlist', pfraud);
    }

    sendList(fraud: TransactionListFraudArg): Promise<TransactionListFraudResponse> {
        return this.requester.post('/fraud/sendlist', fraud);
    }

    sendListSecondWay(fraud: TransactionListFraudArg): Promise<TransactionListFraudResponse> {
        return this.requester.post('/fraud/sendlistsecondway', fraud);
    }

    potentialFraud(fraud: PotentialFraudParameter): Promise<boolean> {
        return this.requester.post('/fraud/potentialfraud', fraud);
    }

    getVariants(gateway: number, reference_id:string, merchants: string[]): Promise<FraudFieldVariants> {
        return this.requester.post('/fraud/getvariants', {gateway: gateway, reference_id:reference_id, merchants: merchants});
    }

    getStatistic(arg: FraudGetStatisticArg): Promise<FraudGetStatisticResponse> {
        return this.requester.post('/fraud/getstatistic', arg);
    }

}
