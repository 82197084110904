<style>
    .login-dialog .login-form .el-input input {
        color: #000;
    }
</style>

<template>
    <el-dialog :title="$t('Sign In')" :visible="visible" :top="getTop()" width="560px" custom-class="login-dialog" :close-on-click-modal="false" :show-close="false">
        <login-form/>
    </el-dialog>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';
    import { LoginForm } from "@/components/login-form";
    import { messages } from "./messages";
    import { AuthModule } from "@/store/modules/auth";

    @Component({
        components: { LoginForm },
        i18n: { messages }
    })
    export default class LoginDialog extends Vue
    {
        get visible() { return !AuthModule.token && this.$route.path !== "/login" }

        getTop() { return Math.max(0, (window.innerHeight - 400) / 2) + "px" }
    }
</script>
