import Vue from 'vue';

import 'normalize.css';
import ElementUI from 'element-ui';
import '@/styles/index.scss';
import '@/permission';
import Cookies from "js-cookie";
import router from '@/router';

import App from '@/App.vue';
import VueI18n from "vue-i18n";
import { ServerApi } from "@/model/ServerApi";
import store from './store';
import { messages } from "@/messages";

Vue.use(VueI18n);
const locale = Cookies.get('locale') ? Cookies.get('locale') : 'en';
const i18n = new VueI18n({ locale: locale, messages , silentTranslationWarn: true});
Vue.use(ElementUI, { i18n: (key, value) => i18n.t(key, value) });

const serverApi = new ServerApi();

Vue.use(ServerApi);

Vue.config.productionTip = false;

(<any>window).app = new Vue({
    router,
    store,
    render: (h) => h(App),
    i18n,
    serverApi
}).$mount('#app');
