<template>
    <el-select :value="gateway" @input="emitInput" :disabled="disabled">
        <el-option v-for="gateway in gateways" :key="gateway.id" :label="gateway.name + (gateway.id ? ' (' + gateway.id + ')' : '')" :value="gateway.id"/>
    </el-select>
</template>

<script lang="ts">
    import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
    import { Gateway } from "@/model/server/Gateway";

    @Component
    export default class GatewaySelector extends Vue
    {
        @Prop({ default:null }) value: number;
        @Prop() disabled: boolean;

        @Watch("value", { immediate:true }) onValueChanged() { this.gateway = this.value }
        @Emit("input") emitInput(e:number) {}

        gateway: number = null;
        gateways: Gateway[] = null;

        created()
        {
            this.$serverApi.gateway.getAll().then(gateways => {this.gateways = gateways;});
        }
    }
</script>
