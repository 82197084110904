import axios, {AxiosRequestConfig} from 'axios';
import {AuthModule} from '@/store/modules/auth';
import {UserServerApi} from './api/UserServerApi';
import {TransactionServerApi} from "./api/TransactionServerApi";
import {CommonServerApi} from "./api/CommonServerApi";
import {CustomListServerApi} from "./api/CustomListServerApi";
import {RuleServerApi} from "./api/RuleServerApi";
import {GatewayServerApi} from "./api/GatewayServerApi";
import {ReportServerApi} from "@/model/api/ReportServerApi";
import {MerchantServerApi} from "@/model/api/MerchantServerApi";
import {Messenger} from "@/model/Messenger";
import {Vue} from "vue-property-decorator";
import {FraudServerApi} from "@/model/api/FraudServerApi";
import {TrendServerApi} from "@/model/api/TrendServerApi";
import {DetectorlistServerApi} from "@/model/api/DetectorlistServerApi";
import {HistoryApi} from "@/model/api/HistoryApi";
import {TokenApi} from "@/model/api/TokenApi";
import {ChargebackApi} from "@/model/api/ChargebackApi";
import {LoginServerApi} from "@/model/api/LoginServerApi";
import {CardStatisticApi} from "@/model/api/CardStatisticApi";
import {CustomerTrustIndicatorApi} from "@/model/api/CustomerTrustIndicatorApi";
import {ReferencesServerApi} from "@/model/api/ReferencesServerApi";
import {NotificationsServerApi} from "@/model/api/NotificationsServerApi";
import {TaskServerApi} from "@/model/api/TaskServerApi";
import {ScheduleServerApi} from "@/model/api/ScheduleServerApi";

export class ServerApi {
    readonly user = new UserServerApi(ServerApi.requester());
    readonly login = new LoginServerApi(ServerApi.requester());
    readonly transaction = new TransactionServerApi(ServerApi.requester({display_errors: true, timeout: 60000}));
    readonly common = new CommonServerApi(ServerApi.requester());
    readonly customList = new CustomListServerApi(ServerApi.requester());
    readonly rule = new RuleServerApi(ServerApi.requester());
    readonly gateway = new GatewayServerApi(ServerApi.requester());
    readonly merchant = new MerchantServerApi(ServerApi.requester());
    readonly task = new TaskServerApi(ServerApi.requester());
    readonly report = new ReportServerApi(ServerApi.requester());
    readonly schedule = new ScheduleServerApi(ServerApi.requester());
    readonly fraud = new FraudServerApi(ServerApi.requester());
    readonly trend = new TrendServerApi(ServerApi.requester());
    readonly detectorlist = new DetectorlistServerApi(ServerApi.requester());
    readonly history = new HistoryApi(ServerApi.requester());
    readonly token = new TokenApi(ServerApi.requester());
    readonly chargeback = new ChargebackApi(ServerApi.requester());
    readonly cardstatistic = new CardStatisticApi(ServerApi.requester());
    readonly customertrustindicator = new CustomerTrustIndicatorApi(ServerApi.requester());
    readonly references = new ReferencesServerApi(ServerApi.requester());
    readonly notifications = new NotificationsServerApi(ServerApi.requester());

    /**
     * Vue plugin method
     */
    static readonly install = (Vue, options?: any) => {
        Vue.prototype.$serverApi = new ServerApi();
    };

    private static get app(): Vue {
        return (<any>window).app;
    }

    private static requester(properties = new RequesterProperties()): IRequester {

        let _requester = axios.create({
            baseURL: window.GlobalConfig.apiUrl + ':' + window.GlobalConfig.apiPort + '/api',
            timeout: properties.timeout
        });

        _requester.interceptors.request.use(
            (config) => {
                let token = AuthModule.token;
                if (token) {
                    config.headers.Authorization = "Bearer " + token;
                }
                return config
            },
            (error) => {
                return Promise.reject(error)
            }
        );

        _requester.interceptors.response.use(
            (response) => {
                if (response.status === 200) {
                    return response.data;
                }
            },
            (error) => {
                if (properties.display_errors) {
                    if (error.response) {
                        let response = error.response;
                        if (response) {
                            switch (response.status) {
                                case 401:
                                    AuthModule.clearToken();
                                    Messenger.error(ServerApi.app.$t("Authorization token expired. Please, relogin."), null, 5);
                                    break;

                                case 400:
                                    Messenger.error(response.data.message, null, 5);
                                    break;

                                default:
                                    Messenger.error("Request error [" + response.status + "]: " + (response.data && response.data.message ? response.data.message : response.data), null, 5);
                                    break;
                            }
                        }
                    } else if (error.message) {
                        Messenger.error(error.message, null, 5);
                    }
                }

                return Promise.reject(error);
            }
        );

        return _requester;
    }
}

export interface IRequester {
    get(url: string, data?: any, config?: AxiosRequestConfig): Promise<any>;

    post(url: string, data?: any, config?: AxiosRequestConfig): Promise<any>;
}

export class RequesterProperties {
    display_errors: boolean;
    timeout: number;

    constructor() {
        this.display_errors = true;
        this.timeout = 25000;
    }
}

declare module 'vue/types/vue' {
    interface Vue {
        readonly $serverApi: ServerApi;
    }
}

declare module 'vue/types/options' {
    interface ComponentOptions<V extends Vue> {
        serverApi?: ServerApi;
    }
}
