<template>

    <span>
        <el-input v-model.number="base" :disabled="disabled" style="width:60px; margin-right:10px" :rules="[{ required:true }, { type:'number' }]"/>
        <el-select v-model="mul" :disabled="disabled">
            <el-option :label="$t('seconds')" :value="1"/>
            <el-option :label="$t('minutes')" :value="60"/>
            <el-option :label="$t('hours')"   :value="3600"/>
            <el-option :label="$t('days')"    :value="86400"/>
        </el-select>
    </span>

</template>

<script lang="ts">
    import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
    import { messages } from "./messages";

    @Component({
        i18n: { messages }
    })
    export default class TimeInterval extends Vue
    {
        private base = 10;
        private mul = 60;

        @Prop({ default:false }) disabled: boolean;

        @Prop({ default:600 }) value: number;
        @Watch("value", { immediate:true }) onValueChanged() {
            if      (this.value % 86400 === 0) { this.base = this.value / 86400; this.mul = 86400 }
            else if (this.value % 3600 === 0) { this.base = this.value / 3600;  this.mul = 3600 }
            else if (this.value % 60 === 0) { this.base = this.value / 60;    this.mul = 60 }
            else                              { this.base = this.value;         this.mul = 1 }
        }

        @Watch("base") onBaseChanged() { this.updateValue() }
        @Watch("mul") onMulChanged() { this.updateValue() }

        @Emit("input") updateValue() { return this.base * this.mul }
    }
</script>
