<template>

    <el-select :value="valueInner" @input="updateValue">
        <el-option label="EN" value="EN" />
        <el-option label="RU" value="RU" />
    </el-select>

</template>

<script lang="ts">
    import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';

    @Component
    export default class LanguageSelector extends Vue
    {
        private valueInner = "EN";
        @Prop({ default:'EN' }) value: string;
        @Watch("value") onValueChanged() { this.valueInner = this.value }
        @Emit("input") updateValue(value) { this.valueInner = value }
    }
</script>
