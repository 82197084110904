export enum MerchantBusiness {
    GAMBLING_EEA = 'GAMBLING_EEA',
    GAMBLING_NON_EEA = 'GAMBLING_NON_EEA',
    CRYPTO = 'CRYPTO',
    FOREX_EEA = 'FOREX_EEA',
    FOREX_NON_EEA = 'FOREX_NON_EEA',
    DATING = 'DATING',
    OTHER = 'OTHER',
    RETAIL_SHOPS = 'RETAIL_SHOPS',
    SKILL_GAMES = 'SKILL_GAMES',
    ADVERTISING_SERVICES = 'ADVERTISING_SERVICES',
    CASHBACK_SERVICES = 'CASHBACK_SERVICES',
}

export class MerchantBusinessTools {
    public static readonly options = Object.keys(MerchantBusiness).map(function (x) {
        return {value: MerchantBusiness[x], label: 'MERCHANT_BUSINESS_' + x};
    });
}
