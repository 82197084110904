<template>
    <el-select :value="innerValue" @input="emitInput">
        <el-option v-for="business in businesses" :key="business.value" :label="$t(business.label)" :value="business.value"/>
    </el-select>
</template>

<script lang="ts">
    import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
    import { MerchantBusinessTools } from "@/model/server/MerchantBusiness";
    import {messages} from "@/views/merchants/merchants-list/messages";

    @Component({
        i18n: { messages }
    })

    export default class BusinessSelector extends Vue
    {
        innerValue: string = null;

        @Prop() value: string;
        @Watch("value", { immediate:true }) onValueChanged() { this.innerValue = this.value; }
        @Emit("input") emitInput(e) {}

        businesses = [ { label:"<Business>", value:null },  ...MerchantBusinessTools.options ];
    }
</script>
