<style lang="scss">
    .horizontal-collapse-transition {
        transition: 0s width ease-in-out, 0s padding-left ease-in-out, 0s padding-right ease-in-out;
    }

    .scrollbar-wrapper {
        overflow-x: hidden !important;

        .el-scrollbar__view {
            height: 100%;
        }
    }

    .el-scrollbar__bar {
        &.is-vertical {
            right: 0;
        }

        &.is-horizontal {
            display: none;
        }
    }
</style>

<style lang="scss" scoped>
    @import "src/styles/variables.scss";

    .container { background-color: rgb(48, 65, 86); padding-top: 5px }
    img { margin-left: 7px; margin-bottom: 20px }
    .cs img.main-logo {
        height: 5.5rem; width: auto;
        display: block; margin: 0.5rem auto 0rem;
    }
    .srp img.main-logo {
        height: 4.5rem; width: auto;
        display: block; margin: 0.5rem auto 0rem;
    }
    .el-scrollbar { height: calc(100% - 70px); }
    .el-menu {
        padding-top: 1rem;
        border: none;
        height: 100%;
        width: 100% !important;
    }
</style>

<template>
    <div class="container">
        <img :src="'/img/' + logoName + '.png'" alt="logo" class="main-logo"/>
        <el-scrollbar wrap-class="scrollbar-wrapper">
            <el-menu
                    :show-timeout="200"
                    :default-active="$route.path"
                    :default-openeds="defaultOpeneds"
                    :collapse="isCollapse"
                    :background-color="backgroundColor"
                    :text-color="textColor"
                    :active-text-color="activeTextColor"
                    mode="vertical"
            >
                <sidebar-item
                        v-for="route in routes"
                        :key="route.path"
                        :item="route"
                        :collapse="collapse"
                />
            </el-menu>
        </el-scrollbar>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Prop } from 'vue-property-decorator'
    import { AppModule } from '@/store/modules/app'
    import SidebarItem from './SidebarItem.vue'
    import { RouteConfig, RouterOptions } from "vue-router/types/router";

    @Component({
        components: {
            SidebarItem
        }
    })
    export default class SideBar extends Vue
    {
        @Prop({default: false}) private collapse!: boolean;

        get sidebar() { return AppModule.sidebar; }
        get logoName() {return window.GlobalConfig.logoName; }
        get backgroundColor() { return window.GlobalConfig.backgroundColor; }
        get textColor() { return window.GlobalConfig.textColor; }
        get activeTextColor() { return window.GlobalConfig.activeTextColor; }
        get routes(): RouteConfig[] {
            return (<RouterOptions>(<any>this.$router).options).routes.find(x => x.path === "/").children;
        }

        get isCollapse() { return !this.sidebar.opened; }

        get defaultOpeneds() { return this.$route.path.split('/'); }
    }
</script>
