import {DataTools} from "@/model/DataTools";
import {MerchantRole} from "@/model/server/MerchantRole";
import {MerchantStatus} from "@/model/server/MerchantStatus";
import {MerchantBusiness} from "@/model/server/MerchantBusiness";

export interface Merchant {
    id?: number;
    gateway: number;
    key_name: string;
    display_name: string;
    business: MerchantBusiness;
    role: MerchantRole;
    status: MerchantStatus;
    first_transaction: Date;
    last_transaction: Date;
    emails: string;
    language: string;
    company: string;
    manager: string;
    country_code: string;
    reference_id?: string;
}

export class MerchantTools {
    static clone(src: Merchant): Merchant {
        return DataTools.cloneObject(src);
    }
}
