<style scoped>
    div {
        display: inline-block;
    }
</style>

<template>
    <div>
        <gateway-selector v-model="gateway" style="width:calc(40% - 6px)" :disabled="disabled"/>
        <merchant-selector :gateway="gateway" v-model="merchant" style="width:60%; margin-left:6px" :disabled="disabled || disabledMerchant"/>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
    import { GatewaySelector } from "@/components/gateway-selector";
    import { MerchantSelector } from "@/components/merchant-selector";

    @Component({
        components: { GatewaySelector, MerchantSelector }
    })
    export default class GatewayMerchantSelector extends Vue
    {
        gateway: number = null;
        merchant: string = null;

        @Prop({ default:()=>({ gateway:null, merchant:null }) }) value: { gateway:number, merchant:string };
        @Prop({ default:false }) disabled: boolean;
        @Prop({ default:false }) disabledMerchant: boolean;
        @Watch("value", { immediate:true }) onValueChanged() { this.gateway = this.value.gateway; this.merchant = this.value.merchant; }
        @Emit("input") updateValue() { this.value.gateway = this.gateway; this.value.merchant = this.merchant; return this.value }

        @Watch("gateway") onGatewayChanged() { this.updateValue() }
        @Watch("merchant") onMerchantChanged() { this.updateValue() }
    }
</script>
