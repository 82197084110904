<template>
    <el-input v-if="type && type.startsWith('string-')" v-model="innerValue" size="mini" @input="onInputChanged" />
    <el-select v-else-if="type && type.startsWith('select-')" v-model="innerValue" clearable size="mini" @input="onSelectChanged">
        <el-option v-for="item in options"
                   :key="item.value"
                   :label="item.label !== '' ? item.label : ' '"
                   :value="item.value"
        />
    </el-select>
</template>

<script lang="ts">
    import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
    import { SmartFilterType } from "./SmartFilterType";
    import { SelectItem } from "@/model/SelectItem";

    @Component
    export default class SmartFilter extends Vue
    {
        @Prop() type: SmartFilterType;

        @Prop({ default:[] }) options: SelectItem[];

        innerValue: any = "";
        @Prop({ default:"" }) value: any;
        @Watch("value", { immediate:true }) onValueChange(e) { this.innerValue = e }
        @Emit("input") onSelectChanged(e) {}

        delayTimer: any;
        onInputChanged(e)
        {
            if (this.delayTimer) {
                clearTimeout(this.delayTimer);
            }
            this.delayTimer = setTimeout(() => this.$emit("input", e), 500);
        }
    }
</script>
