<style lang="scss" scoped>
    .navbar {
        height: 50px;
        line-height: 50px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .12), 0 0 3px 0 rgba(0, 0, 0, .04);
        overflow-y: hidden;
    }

    .hamburger-container {
        line-height: 54px;
        float: left;
        padding: 0 10px;
    }

    .screenfull {
        position: absolute;
        right: 90px;
        top: 16px;
        color: red;
    }

    .languageSwitcher {
        float: right;
        height: 20px;
        margin-right: 30px;
    }

    .userMenu-container {
        float: right;
        height: 30px;
        margin-right: 20px;
    }

    .userName {
        cursor: pointer;
        margin-top: 5px;
        position: relative;
        line-height: initial;
    }
    .el-icon-caret-bottom {
        font-size: 12px;
    }
</style>

<template>
    <div class="navbar">
        <hamburger :toggle-click="toggleSideBar" :is-active="sidebar.opened" class="hamburger-container"/>
        <breadcrumb/>

        <el-dropdown class="userMenu-container" trigger="click">
            <div class="userMenu">
                <span class="userName">{{userName}}</span>
                <i class="el-icon-caret-bottom" />
            </div>
            <el-dropdown-menu slot="dropdown" class="user-dropdown">
                <el-dropdown-item>
                    <span style="display:block;" @click="logout">{{$t('Logout')}}</span>
                </el-dropdown-item>
                <el-dropdown-item>
                    <span style="display:block;" @click="logoutall">{{$t('Logout from all devices')}}</span>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>

        <language-switcher class="languageSwitcher" />
    </div>
</template>

<script lang="ts">
    import { Breadcrumb } from './components/breadcrumb'
    import { Hamburger } from './components/hamburger'
    import { LanguageSwitcher } from '@/components/language-switcher'
    import { Component, Vue } from 'vue-property-decorator'
    import { AppModule } from '@/store/modules/app'
    import { AuthModule } from '@/store/modules/auth'
    import { messages } from "./messages";
    import {ServerApi} from "@/model/ServerApi";

    @Component({
        components: {
            Breadcrumb,
            Hamburger,
            LanguageSwitcher
        },
        i18n: { messages }
    })
    export default class Navbar extends Vue {
        get sidebar() {
            return AppModule.sidebar
        }

        get userName() {
            return AuthModule.user ? AuthModule.user.name : "-";
        }

        private toggleSideBar() {
            AppModule.ToggleSideBar(false)
        }

        private async logout() {
            await new ServerApi().user.logoff();
            AuthModule.logout().then(() => {
                location.reload() // to avoid bugs
            })
        }
        private async logoutall() {
            await new ServerApi().user.logoffall();
            AuthModule.logout().then(() => {
                location.reload() // to avoid bugs
            })
        }
    }
</script>
