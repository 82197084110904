import {Report} from "@/model/server/reporting/Report";
import {IRequester} from "@/model/ServerApi";
import {ReportGenerateArg} from "@/model/bindings/ReportGenerateArg";
import {DataTools} from "@/model/DataTools";

export class ReportServerApi {
    constructor(private requester: IRequester) {
    }

    async getAll(): Promise<Report[]> {
        let reports: Report[] = await this.requester.get('/report/list');
        for (let report of reports) {
            report.create_time = DataTools.parseServerDate(report.create_time);
            report.start_generation_time = report.start_generation_time ? DataTools.parseServerDate(report.start_generation_time) : null;
            report.finish_generation_time = report.finish_generation_time ? DataTools.parseServerDate(report.finish_generation_time) : null;
            report.kill_time = report.kill_time ? DataTools.parseServerDate(report.kill_time) : null;
        }
        return reports;
    }

    submit(arg: ReportGenerateArg): Promise<boolean> {
        return this.requester.post('/report/submit', arg);
    }

    cancel(id: number): Promise<boolean> {
        return this.requester.post('/report/cancel?id=' + id);
    }
}
