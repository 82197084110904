export enum MerchantStatus {
    TESTING = 'TESTING',
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export class MerchantStatusTools {
    public static readonly options = [
        {value: MerchantStatus.TESTING, label: 'MERCHANT_STATUS_TESTING'},
        {value: MerchantStatus.ACTIVE, label: 'MERCHANT_STATUS_ACTIVE'},
        {value: MerchantStatus.INACTIVE, label: 'MERCHANT_STATUS_INACTIVE'},
    ];
}
