<style lang="scss" scoped>
    .app-breadcrumb.el-breadcrumb {
        display: inline-block;
        font-size: 14px;
        line-height: 50px;
        margin-left: 10px;
    }

    .no-redirect {
        color: #8899af;
        cursor: text;
    }
</style>

<template>
    <el-breadcrumb class="app-breadcrumb" separator="/">
        <transition-group name="breadcrumb">
            <el-breadcrumb-item v-for="(item, index) in breadcrumbs" :key="item.path">
                <span v-if="item.redirect === 'noredirect' || index === breadcrumbs.length-1" class="no-redirect">{{ $t(item.meta.title) }}</span>
                <a v-else @click.prevent="handleLink(item)">{{ $t(item.meta.title) }}</a>
            </el-breadcrumb-item>
        </transition-group>
    </el-breadcrumb>
</template>

<script lang="ts">
    import { Component, Vue, Watch } from 'vue-property-decorator'
    import { RouteRecord } from 'vue-router'
    import * as pathToRegexp from 'path-to-regexp/dist'

    @Component
    export default class Breadcrumb extends Vue {
        breadcrumbs: RouteRecord[] = [];

        created()
        {
            this.getBreadcrumb();
        }

        @Watch('$route')
        onRouteChange()
        {
            this.getBreadcrumb();
        }

        getBreadcrumb()
        {
            let matched = this.$route.matched.filter((item) => item.name);
            this.breadcrumbs = matched.filter((item) => item.meta && item.meta.title && item.meta.breadcrumb !== false);
        }

        pathCompile(path: string)
        {
            // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
            const { params } = this.$route;
            const toPath = pathToRegexp.compile(path);
            return toPath(params);
        }

        handleLink(item: any)
        {
            const { redirect, path } = item;
            if (redirect) {
                this.$router.push(redirect);
                return;
            }
            this.$router.push(this.pathCompile(path));
        }
    }
</script>
