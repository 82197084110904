import {IRequester} from "@/model/ServerApi";
import {TrendFieldVariants} from "@/model/server/TrendFieldVariants";
import {TrendGetArg} from "@/model/bindings/TrendGetArg";
import {TrendGetResponse} from "@/model/bindings/TrendGetResponse";

export class TrendServerApi {
    constructor(private requester: IRequester) {
    }

    get(arg: TrendGetArg): Promise<TrendGetResponse> {
        return this.requester.post('/trend/get', arg);
    }

    getVariants(): Promise<TrendFieldVariants> {
        return this.requester.post('/trend/getvariants');
    }

    recalculate(start: Date, end: Date, gateway: number): Promise<void> {
        return this.requester.post('/trend/recalculate?start=' + encodeURIComponent(start.toISOString()) + "&end=" + encodeURIComponent(end.toISOString())
            + "&gateway=" + gateway);
    }
}
